.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 20px;
}

.title {
  padding: 30px;
  font-size: 38px;
  font-weight: bold;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.grid {
  display: grid;
  grid-template-columns: 512px 512px;
  gap: 50px;
}

.grid img {
  width: 100%;
  object-fit: cover;
  border-radius: 16px;
}

.gridTextBox {
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
}

.gridTextBoxTitle {
  font-size: 32px;
  padding: 20px;
  font-weight: 800;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.gridTextBoxText {
  font-size: 18px;
  padding: 20px;
  line-height: 1.6;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

@media (max-width: 768px) {
  .grid {
    grid-template-columns: 1fr;
    gap: 20px;
    width: 80%;
  }
}
