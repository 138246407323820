.container {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
  position: relative;
  background-image: url("mantas.jpeg");
  background-size: cover;
}

.pageTitle {
  font-size: 58px;
  font-weight: bold;
  text-align: center;
}

.container * {
  color: aliceblue;
}

.priceList {
  list-style: none;
  padding-left: 0;
}
.priceList li {
  display: flex;
  justify-content: space-between;
  border-bottom: aliceblue dashed 1px;
  align-items: center;
  gap: 10px;
}

.navigation {
  display: flex;
  justify-content: space-evenly;
  font-size: 20px;
}
